.intent {
  position: relative;
  padding-bottom: 10px;

  &:not(:first-child) {
    padding-top: 10px;
    border-top: 1px solid var(--app-content-separator-border-color);
  }

  .actions {
    display: none;
  }

  &:hover {
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.summary {
  font-size: 12px;
  margin: 0;
}

.infos {
  position: absolute;
  right: 10px;
  top: 2px;
}

.unresolved {
  border-right: 3px solid #f10303;
}

.resolved {
  border-right: 3px solid #1ab805;
}
