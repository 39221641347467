.inputContainer {
    height: calc(var(--conversation-input-height) - 10px);
    padding: 0px 10px 10px;
    color: var(--color-white);
    position: relative;
    box-sizing: content-box;
}

.input {
    height: calc(var(--conversation-input-height) - 25px - 10px)!important;
    min-height: calc(var(--conversation-input-height) - 25px - 10px)!important;
    border: none;
    background: transparent;
    padding: 5px 10px;

    &:focus,
    &:hover {
      box-shadow: none!important;
      background: transparent;
    }

    resize: none!important;
}

.inputActionsContainer {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 5px;
}

.inputContainerInner {
    border: 1px solid var(--color-gray-1);
    background: var(--color-gray-6);
    border-radius: 6px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
}

.inputActionsContainerGroup {}

.inputActionsContainerGroupButton {
    padding: 5px 10px;
}

@media screen and (max-width: 768px) {
    .inputActionsContainer {
        justify-content: flex-start;
    }

    .input {
        font-size: 16px;
    }
}
