.container {
  :global {
    .ant-collapse {
      background-color: transparent;
      border: none;

      .ant-collapse {
        &-header {
          padding: 0;
        }

        &-content {
          border-top: none;

          .ant-collapse {
            &-content-box {
              padding: 20px 0 0;
            }
          }
        }
      }
    }
  }
}
