.bubble {
  padding: 6px 12px;
  border-radius: 18.5px;
  background-color: var(--color-gray-5);
  max-width: 80%;
  color: var(--color-solid);
  word-break: break-word;
  text-align: justify;

  p {
    margin: 0;
    text-align: justify;
  }
}

.own {
  &.bubble {
    background-color: #4D7CFE;
    color: white;

    a {
      color: white;
      text-decoration: underline;
    }
  }
}
