:root {
  --color-primary-lighter: #2D74FF;
  --color-primary: #2D55FB;
  --color-primary-darker: #1E60FF;

  --color-primary-dark: #050507;

  --color-gray-1: #E8ECEF;
  --color-gray-2: #F8FAFB;
  --color-gray-3: #98A9BC;
  --color-gray-4: #778CA2;
  --color-gray-5: #F2F4F6;
  --color-gray-6: #F9FAFB;

  --color-solid: #252631;
  --color-pink-primary: #FE4D97;

  --color-success: #00D68F;
  --color-danger: #FF3D71;
;

  --header-height: 56px;
  --content-header-height: 0px;
}

.ant-theme-light-dark {}

.app-layout-sidebar {
  background-color: #fff;
}

.ant-menu-title-content {
  font-weight: 600;
}

.ant-menu {
  .ant-menu-item {
    &.ant-menu-item {
      &-selected {
        .ant-menu-title-content {
          color: var(--color-primary);
        }

        .ant-menu-item {
          &-icon {
            color: var(--color-primary);
          }
        }
      }
    }

    .ant-menu-item {
      &-icon {
        color: var(--color-gray-4);

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.app-layout-content {
  background-color: var(--color-gray-5);

  .app-layout-content-header {
    border-bottom: 1px solid var(--color-gray-1);
  }
}

