.fw-bold {
  font-weight: 700;
}

.h5 {
  font-size: 16px;
}

.color-primary {
  color: var(--color-primary);
}

.color-success {
    color: var(--color-success);
}

.color-danger {
    color: var(--color-danger);
}

.gray-4 {
  color: var(--color-gray-4);
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.text-label {
  font-size: 12px;
}

.text-sm {
  font-size: 12px;
}
