.contentContainer, .contentContainerLeft {
  height: calc(100dvh - 78px);
}

.contentContainerLeft {
  overflow: auto;
  padding-bottom: 20px;
}

.previewContainer {
  background: var(--color-gray-1);
  height: calc(100% - 20px);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iframe {
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 9999;
  max-height: 800px;
  max-width: 400px;
  flex: 1;
}
