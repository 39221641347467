.ant-dropdown {
  box-shadow: 0 5px 25px rgba(91, 91, 91, 0.1);
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--color-gray-1);

  .ant-dropdown {
    &-menu {
      padding: 6px 5px;

      .ant-dropdown-menu-item {
        padding: 5px 6px;

        .ant-dropdown-menu-title-content {
          font-weight: 600;
        }

        .ant-dropdown-menu-item-icon {
          margin-inline-end: 11px;

          svg {
            width: 18px;
            height: 18px;
            color: var(--color-gray-4);
          }
        }
      }
    }
    &-header {
      padding: 14px 24px;
    }
  }

  .app-dropdown-content {
    background-color: transparent;
  }
}
