.container {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex-direction: row;
  margin-bottom: 5px;

  .avatar {
    min-width: 24px;
    min-height: 24px;
    height: 24px;
  }

  .timestamp {
    align-self: center;
    color: var(--color-gray-4);
    font-size: 12px;
  }
}

.containerOwn {
  flex-direction: row-reverse;
}

.containerDivergent {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}
