.loader {
    width: 40px;
    aspectRatio: 1;
    animation: l1 2s infinite;
}
@keyframes l1 {
    0%   {transform: perspective(150px) rotateX(  0deg)}
    50% {transform: perspective(150px) rotateX(180deg)}
    100% {transform: perspective(150px) rotateX(0deg)}
}
