:root {
  --conversation-header-height: 56px;
  --conversation-input-height: 100px;
  --conversation-messages-height: calc(100dvh - var(--conversation-header-height) - var(--conversation-input-height) - var(--content-header-height) - var(--app-content-padding) - var(--app-content-padding));
}

.header {
    height: var(--conversation-header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: var(--color-white);
    border-bottom: 1px solid var(--color-gray-1);

    .user {
        display: flex;
        align-items: center;
        gap: 12px;

        .name {
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.messages {
    height: var(--conversation-messages-height);
    overflow-y: auto;
    padding: 16px;
}

.conversationInfo {
    width: 350px;
    border-left: 1px solid var(--app-content-separator-border-color);
}

.conversationContainer {
    flex: 1;
}

.container {
    display: flex;
}

@media screen and (max-width: 768px) {
    .conversationContainer,
    .conversationInfo {
        width: 100vw;
        min-width: 100vw;
    }

    :root {
        //--conversation-messages-height: calc(100dvh - var(--conversation-header-height) - var(--conversation-input-height) - var(--content-header-height) - var(--app-content-padding) - var(--app-content-padding) - 80px);
    }
}
