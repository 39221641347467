.card {
  padding: 12px;
  cursor: pointer;
  display: flex;
  gap: 12px;

  &:not(:first-child) {
    border-top: 1px solid var(--color-gray-1);
  }

  &:hover {
    background-color: var(--color-gray-5);
  }

  .avatar {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 50%;
    background-color: var(--color-gray-1);
  }

  .title {
    font-weight: 500;
    margin: 0;
    color: black;
  }

  .excerpt {
    color: var(--color-gray-4);
    margin-bottom: 0;
    margin-top: 5px;
    // 2 max lines
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .time {
    color: var(--color-gray-4);
    font-size: 0.8rem;
    margin: 0;
    white-space: nowrap;
  }

  .badge {
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    min-width: 18px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f40a0a;
    color: #FFFFFF;
  }
}
