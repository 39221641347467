.container {
  display: flex;
  background-color: white;
  height: 100dvh;

  --inbox-page-padding: var(--padding-medium);
}

.sidebar {
  width: 230px;
  border-right: 1px solid var(--app-content-separator-border-color);
}

.conversations {
  width: 320px;
  border-right: 1px solid var(--app-content-separator-border-color);
}

.conversation {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .sidebar,
  .conversations {
    width: 100vw;
    min-width: 100vw;
  }

  .conversation {
    width: 200vw;
    min-width: 200vw;
  }

  .container {
    width: 100vw;
    overflow: hidden;
  }
}
