.intent {
  &:not(:first-child) {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--app-content-separator-border-color);
  }
}

.header {
  height: var(--header-height);
  border-bottom: 1px solid var(--app-content-separator-border-color);
  display: flex;
  align-items: center;
  padding: 10px;

  :global {
    .ant-select {
      margin-right: -9px;
    }
  }
}

.content {
  max-height: calc(100dvh - var(--header-height));
}
