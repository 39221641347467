.container {
  &:not(:last-child) {
    border-bottom: 1px solid var(--app-content-separator-border-color);
  }

  :global {
    .ant-select {
      margin-right: -10px;
    }

    .ant-collapse {
      .ant-collapse-header {
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
      }
    }
  }

  padding: 10px;
}

.collapse {
  :global {
    .ant-collapse-item {
      .ant-collapse-header,
      .ant-collapse-content-box{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  border-radius: 0;
}

.title {
  font-weight: bold;
  margin: 0;
}
