.ant-menu {
  .ant-menu-item {
    display: flex;
  }
}

.ant-btn {
  &:not(:disabled):focus-visible {
    outline-offset: -1px;
  }
}

.ant-select-dropdown-small {
  .ant-select-item.ant-select-item-option {
    padding: 8px 4px;
    min-height: 20px;
  }
}

.ant-select.ant-select-borderless {
  .ant-select-selection-item {
    font-weight: 600;
  }

  .ant-select-arrow {
    color: black;
  }
}

.ant-select-dropdown {
  .ant-select-item.ant-select-item-option {
    margin-block: 1px;
  }

  &-small {
    .ant-select-item.ant-select-item-option {
      padding: 6px 8px;
    }
  }
}
