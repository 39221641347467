.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.bg-gray-4 {
    background-color: var(--color-gray-4);
}

.bg-gray-5 {
    background-color: var(--color-gray-5);
}

.whitespace-nowrap {
  white-space: nowrap;
}
