:root {
  --app-content-padding: 0px;
  --app-content-inner-padding: 24px;
  --app-content-separator-border-color: var(--color-gray-1);

  --padding-medium: 12px;
  --padding-large: 24px;
}

.app-layout-separator-left {
  border-left: 1px solid var(--app-content-separator-border-color);
}

.app-layout-separator-right {
  border-right: 1px solid var(--app-content-separator-border-color);
}

.app-layout-sidebar {
  border-right: 1px solid var(--app-content-separator-border-color);
  overflow: hidden;

  .app-layout-sidebar {
    &-header {
      height: var(--header-height);
      border-bottom: 1px solid var(--color-gray-1);
      padding: 12px 24px;
      display: block;

      .app-layout-sidebar-header-logo-text {
        max-height: 100%;
        transition: visibility 0.3s;
        display: block;
      }

      .app-layout-sidebar-header-logo {
        display: none;
        transition: visibility 0.3s;
      }
    }

    &-content {
      height: calc(100dvh - var(--header-height));
      display: flex;
      flex-direction: column;
      padding: 12px;

      .ant-menu {
        border: none;

        .ant-menu-item {
          margin: 2px 0;
        }
      }
    }
  }

  &.ant-layout-sider {
    &-collapsed {
      .app-layout-sidebar {
        &-header {
          display: flex;
          justify-content: center;
          align-items: center;

          .app-layout-sidebar-header-logo-text {
            display: none;
          }

          .app-layout-sidebar-header-logo {
            max-height: 35px;
            display: block;
          }
        }
      }
    }
  }
}

.app-layout-mobile-sidebar-open-button {
  position: fixed;
  bottom: 12px;
  right: 80px;
  z-index: 1000;
  height: 58px;
  width: 58px;
  box-sizing: border-box;
}

.app-layout-content {
  .app-layout-content {
    &-header {
      padding: 0 24px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-container {
      padding: var(--app-content-padding) var(--app-content-padding) 0 var(--app-content-padding);
      height: calc(100dvh - var(--content-header-height) - 24px);
      overflow-x: auto;
    }
  }
}

:root {
  //--app-sub-page-layout-padding: var(--padding-medium);
  --app-sub-page-layout-padding: 0px;
  --layout-height: calc(100dvh - var(--content-header-height) - calc(var(--app-content-padding) * 2) - calc(var(--app-content-padding) * 2) - calc(var(--app-sub-page-layout-padding) * 2));
  --footer-padding: 24px;
  --footer-height: 48px;
  --total-footer-height: calc(var(--footer-height) + calc(var(--footer-padding) * 2));
  --local-header-padding: 24px;
  --local-header-height: 30px;
  --total-local-header-height: calc(var(--local-header-height) + calc(var(--local-header-padding) * 2));
  --app-sub-page-layout-content-height: calc(var(--layout-height) - var(--total-local-header-height));
  --app-sub-page-layout-content-with-tabs-height: calc(var(--layout-height) - var(--total-local-header-height)) - 48px;
}

.app-sub-page-layout {
  //border: 1px solid var(--app-content-separator-border-color);
  border-radius: 4px;

  height: var(--layout-height);
  background-color: #FFF;
  display: flex;

  &-container {
    padding: var(--app-sub-page-layout-padding);
  }

  &:not(.app-sub-page-layout-full-width) {
    //max-width: 1350px;
  }

  .app-sub-page-layout-main-content-container {
    display: flex;
    flex: 1;

    &-width {
      &-medium {
        max-width: 1200px;
      }
    }
  }

  .app-sub-page-layout-sidebar {
    width: 220px;
    border-right: 1px solid var(--color-gray-1);
    //padding-top: 78px;
    padding: 70px 10px 0 10px;
  }

  .app-sub-page-layout-content-right {
    flex: 1;
  }

  .app-sub-page-layout-content-sidebar {
    width: 350px;
    border-left: 1px solid var(--app-content-separator-border-color);
  }

  .app-sub-page-layout-content-right {
    height: var(--layout-height);
  }

  .app-sub-page-layout-content-container {
    display: flex;
    flex-direction: column;
  }

  .app-sub-page-layout-content {
    height: var(--app-sub-page-layout-content-height);

    &.app-sub-page-layout-content-with-footer {
      height: calc(var(--layout-height) - var(--total-footer-height) - var(--total-local-header-height));
    }

    overflow: auto;
    padding: 0 var(--app-content-inner-padding) 0 var(--app-content-inner-padding);
  }

  .app-sub-page-layout-footer {
    padding: var(--footer-padding);
  }

  .app-sub-page-layout-header {
    padding: var(--local-header-padding);
    height: calc(var(--local-header-height) + calc(var(--local-header-padding) * 2));
    display: flex;
    align-items: center;
    justify-content: space-between;

    .app-sub-page-layout-header-title {
      font-size: 20px;
      font-weight: 700;
      margin: 0;
    }
  }

  .app-sub-page-layout-footer,
  .app-sub-page-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1550px) {
  .app-sub-page-layout {
    .app-sub-page-layout-main-content-container {
      &-width {
        &-medium {
          max-width: calc(100% - 50px);
        }
      }
    }
  }
}

@media screen and (max-width: 999px) {
  :root {
    --app-sub-page-layout-content-height: calc(var(--layout-height) - var(--total-local-header-height) - 66px);
  }

  .app-sub-page-layout {
    max-width: 100%;
    flex-direction: column;

    .app-sub-page-layout-sidebar {
      width: auto;
      border-right: none;
      padding: 20px 14px 0 14px;
    }

    .app-sub-page-layout-content-right {
      height: calc(var(--layout-height) - 66px);
    }

    .app-sub-page-layout-content {
      --local-header-height: 96px;
      --total-local-header-height: calc(var(--local-header-height) + calc(var(--local-header-padding) * 2));
    }
  }
}

.mobile-only {
  display: none;
}

@media screen and (max-width: 768px) {
  .mobile-only {
    display: block;
  }

  .ant-layout {
    .app-layout-sidebar {
      position: fixed;
      background: #fff;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;

      &-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 3;
      }

      .app-layout-sidebar-header .app-layout-sidebar-header-logo-text {
        width: auto;
        height: auto;
      }
    }
  }

  .app-layout-content {
    .app-layout-content {
      &-container {
        height: calc(100dvh - var(--content-header-height));
      }
    }
  }
}

.app-page-content {
  --app-page-content-padding: 12px;

  padding: var(--app-page-content-padding);

  .app-page-content-padding-none {
    --app-page-content-padding: 0;
  }

  &-layout {
    &-centered {
      max-width: 1200px;
      margin: auto;
    }
  }
}

.app-page-vertical-section {
  &-header {
    height: var(--header-height);
    padding: var(--padding-medium);
    border-bottom: 1px solid var(--app-content-separator-border-color);
    display: flex;
    align-items: center;
  }

  &-content {
    height: calc(100dvh - var(--header-height));
    padding: var(--padding-medium);
  }
}
